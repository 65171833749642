.services-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 4%;
  margin-top: 20px;
}

.service-block {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 0.2s;
}

.service-block:hover {
  transform: translateY(-5px);
}

.service-block h2 {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #333;
}

.pagination-container {
  display: flex;
  justify-content: center;
}

.pagination-container button {
  background-color: #ddd;
  border: none;
  margin: 0 5px;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-container button.active {
  background-color: #007bff;
  color: white;
}

.pagination-container button:hover {
  background-color: #555;
  color: white;
}

.titul-services {
  top: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  text-align: center;
}

.button-container {
  text-align: center;
  margin-top: 20px;
}

button {
  padding: 12px 24px;
  font-size: 18px;
  background-color: rgb(15, 15, 15);
  color: #fff;
  border: 1px solid #555;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

button:hover {
  background-color: #444;
  border-color: #777;
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  button {
    width: 100%;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  button {
    font-size: 14px;
    padding: 10px 20px;
  }
}

@media (max-width: 768px) {
  .titul-reasons {
    font-size: 1.8rem;
    top: 40px;
  }
}

@media (max-width: 480px) {
  .titul-reasons {
    font-size: 1.5rem;
    top: 10px;
    padding: 0 10px;
  }
}

@media (max-width: 768px) {
  .services-grid-container {
    grid-template-columns: repeat(2, 1fr);
    padding: 10%;
  }

  .service-block {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .services-grid-container {
    grid-template-columns: 1fr;
    padding: 5%;
  }

  .service-block {
    padding: 10px;
  }
}
