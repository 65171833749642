.language-selector {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(15, 15, 15, 0.8);
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.language-selector select {
  border: none;
  background-color: rgba(15, 15, 15, 0.9);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  padding: 5px;
}

.language-selector option {
  background-color: rgb(15, 15, 15);
  color: #fff;
}
