.reasons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.reasons-container h1,
.reasons-container img {
  display: flex;
  justify-content: center;
  margin: 0 20px;
  flex: 1;
}

.reasons-container-reviews {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.reasons-container-reviews h1,
.reasons-container-reviews img {
  display: flex;
  justify-content: center;
  margin: 0 20px;
}

.custom-button {
  padding: 20px 40px;
  font-size: 24px;
  display: block;
  margin: 0 auto;
}
