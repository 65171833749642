.reasons-container-socials {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background-color: rgb(15, 15, 15);
  padding: 20px;
}

.reasons-container-socials img {
  margin: 0 20px;
  max-width: 50px;
  height: auto;
}
