.centered-image {
  border-radius: 50%;
  width: 400px;
  height: 400px;
  display: block;
  margin: 0 auto;
  object-fit: cover;
}

@media (max-width: 768px) {
  .centered-image {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 480px) {
  .centered-image {
    width: 200px;
    height: 200px;
  }
}
