.reasons-container-reviews {
  display: flex;
  overflow-x: auto;
  padding: 5% 5% 5% 0;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: flex-start;
}

.reasons-container-reviews img {
  min-width: 200px;
  max-width: 90vw;
  flex-shrink: 0;
  scroll-snap-align: start;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  object-fit: contain;
  margin-bottom: 10px;
}

.reasons-container-reviews::-webkit-scrollbar {
  height: 8px;
}

.reasons-container-reviews::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.reasons-container-reviews::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.titul-review {
  top: 25px;
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .titul-review {
    font-size: 1.8rem;
  }

  .reasons-container-reviews {
    padding: 3%;
    margin-left: 0;
    margin-right: 10px;
  }

  .reasons-container-reviews img {
    min-width: 250px;
    max-width: 90vw;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .titul-review {
    font-size: 1.5rem;
    padding: 0 10px;
  }

  .reasons-container-reviews {
    padding: 2%;
    margin-left: 5px;
    margin-right: 5px;
    flex-direction: column;
    align-items: center;
    overflow-x: visible;
  }

  .reasons-container-reviews img {
    min-width: 100%;
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
}
