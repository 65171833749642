.examples-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  justify-items: center;
  padding: 100px;
}

.examples-grid-container img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.examples-bg {
  background: rgb(15, 15, 15);
}

.titul {
  top: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

@media (max-width: 480px) {
  .examples-grid-container {
    grid-template-columns: repeat(2, 1fr);
    padding: 20px;
    grid-gap: 10px;
  }

  .examples-grid-container img {
    max-width: 100%;
  }

  .titul {
    top: 30px;
  }
}
