.reasons-scroll-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding: 5%;
  gap: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.reason-block {
  flex-shrink: 0;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  scroll-snap-align: start;
}

.reasons-scroll-container::-webkit-scrollbar {
  height: 8px;
}

.reasons-scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.reasons-scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.reason-block h1 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.reason-block p {
  font-size: 1em;
  line-height: 1.6;
}

.titul-reasons {
  top: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  text-align: center;
}

@media (max-width: 768px) {
  .titul-reasons {
    font-size: 1.8rem;
    top: 40px;
  }
}

@media (max-width: 480px) {
  .titul-reasons {
    font-size: 1.3rem;
    top: 10px;
    padding: 0 10px;
  }
}

@media (max-width: 768px) {
  .reasons-scroll-container {
    padding: 3%;
    gap: 15px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .reason-block {
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
  }

  .reason-block h1 {
    font-size: 1.3em;
  }

  .reason-block p {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .reasons-scroll-container {
    display: block;
    overflow-x: visible;
    scroll-snap-type: none;
    padding: 2%;
    margin-left: 0;
    margin-right: 0;
    gap: 0;
  }

  .reason-block {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }

  .reason-block h1 {
    font-size: 1.1em;
    margin-bottom: 5px;
  }

  .reason-block p {
    font-size: 0.85em;
  }
}
