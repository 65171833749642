.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.head-container {
  position: relative;
  width: 100%;
  height: 750px;
}

.head-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.head-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
}
